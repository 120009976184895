import Case from './case'
export default {
  data () {
    return {
      data: [], // 分页数据
      total: -1,
      page: 1,
      pageSize: 10
    }
  },
  components: {
    'scroll-list': () => import('@/components/ScrollList.vue'),
    'case': Case
  }
}
