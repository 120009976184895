<template>
  <div @click="toDetail" class="card-box case">
    <div class="case-content border-bottom-1px">
      <div class="img-wrapper">
        <img v-lazy="coverImg" alt="">
      </div>
      <div class="info-wrapper">
        <h4>{{data.questionTypeName}}</h4>
        <p>问题编号：{{data.questionNum}}</p>
        <p>现场地址：{{data.questionAddr}}</p>
        <p>上报时间：{{data.createTime}}</p>
      </div>
    </div>
    <div class="status" :class="{'processing': data.status === 1}">{{data.questionStatus | statusFilter}}</div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  computed: {
    coverImg () {
      let coverImgUrl = this.data.coverImgUrl
      if (coverImgUrl) {
        return coverImgUrl.split(';')[0] || ''
      } else {
        return ''
      }
    }
  },
  filters: {
    statusFilter (val) {
      switch (val) {
        case 1: return '待审核'
        case 2: return '审核通过'
        case 3: return '审核不通过'
        case 4: return '不予受理'
        case 5: return '结案'
        case 6: return '其他'
        case 7: return '重复案件'
      }
    }
  },
  methods: {
    toDetail () {
      this.$router.push('/reportHistory/' + this.data.discloseId)
    }
  }
}
</script>

<style lang="scss" scoped>
  .case{
    margin-bottom: 20px;
    .case-content{
      padding-bottom: 24px;
      margin-bottom: 24px;
      display: flex;
      .img-wrapper, img{
        width: 164px;
        height: 164px;
      }
      .info-wrapper{
        margin-left: 26px;
        h4{
          font-size: 28px;
          font-weight: bold;
          color: #333;
          margin-bottom: 12px;
        }
        p{
          font-size: 28px;
          color: #999;
          line-height: 1.5;
        }
      }
    }
    .status{
      color: #666;
      font-size: 26px;
      &.processing{
        color: #FF5167;
      }
    }
  }
</style>
