<template>
  <scroll-list
    :page="page"
    :pageSize="pageSize"
    :total="total"
    :data="data"
    :getData="getData">
    <case v-for="item in data" :key="item.id" :data="item"></case>
  </scroll-list>
</template>

<script>
import listMixin from './listMixin'
import APIS from '@/api/index'
const { getHistoryDisclose } = APIS
export default {
  mixins: [listMixin],
  methods: {
    // 获取所有上报列表
    async getData (isRefresh) {
      if (isRefresh) {
        this.page = 1
      }

      let res = await getHistoryDisclose({
        start: this.page,
        limit: this.pageSize
      })

      if (isRefresh || this.total === -1) {
        if (res.success) {
          this.total = res.totalCount
        } else {
          throw new Error(res.message)
        }
      }

      this.data = isRefresh ? res.data : this.data.concat(res.data)
      this.page++
    }
  }
}
</script>
